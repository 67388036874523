import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import { defineComponent } from "vue";
import authStore from "@/store/auth";
import authHttp from "@/http/auth";
import { helperMixin } from "@/mixins";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        BaseFormApp,
        BaseFormGroup,
        CompanyLogo,
        TeleportToContentFooter,
        BaseFileInput,
    },
    data() {
        return {
            submitting: false,
            validationErrors: {},
            form: {
                email: authStore().state.user.email,
                first_name: authStore().state.user.first_name,
                last_name: authStore().state.user.last_name,
            },
        };
    },
    computed: {
        authStore,
        authHttp,
    },
    methods: {
        async submit() {
            var _a;
            this.validationErrors = {};
            this.submitting = true;
            const { response } = (_a = (await this.requestHandler(this.authHttp.updateUser(this.form)))) !== null && _a !== void 0 ? _a : { response: null };
            if (response) {
                this.authStore.state.user = response.data;
            }
            this.validationErrors = this.authHttp.validationErrors;
            this.submitting = false;
        },
    },
});
